import { noopFn, retryOnCsrfTokenMissmatch } from '@shared/utils';
import axios, { AxiosResponse } from 'axios';
import { getConfig } from 'config/getConfig';
import { TOverviewData } from './TOverviewData';

export const api = axios.create({
  baseURL: getConfig('CARD_SERVICE_URL') + 'api/',
  withCredentials: true,
});
export const checkSession = () => api.get('session').catch(noopFn);

retryOnCsrfTokenMissmatch(api, checkSession);

const getData = <T>(res: AxiosResponse<T>) => res.data;

export const getOverviewData = () =>
  api.get<TOverviewData>('mgmt/overview').then(getData);
