interface Sortable {
  updatedAt: string;
}
const sortByUpdatedAt = <T extends Sortable>(
  a: T,
  b: T,
  ascending: boolean
) => {
  if (a.updatedAt === b.updatedAt) {
    return 0;
  }
  const dir = ascending ? 1 : -1;

  return new Date(a.updatedAt) > new Date(b.updatedAt) ? 1 * dir : -1 * dir;
};

export const sortByUpdatedAtAsc = <T extends Sortable>(a: T, b: T) =>
  sortByUpdatedAt(a, b, true);
export const sortByUpdatedAtDesc = <T extends Sortable>(a: T, b: T) =>
  sortByUpdatedAt(a, b, false);
