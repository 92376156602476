import { AxiosError, AxiosInstance } from 'axios';
import { HttpStatusCodes } from '../HttpStatusCodes';

export const retryOnCsrfTokenMissmatch = (
  axios: AxiosInstance,
  getCsrfCookie: () => Promise<any>,
  maxRetries: number = 2
) => {
  let retryCounter = 0;

  axios.interceptors.response.use(
    (res) => {
      retryCounter = 0;
      return res;
    },
    async (err: Error) => {
      const error = err as AxiosError;

      if (
        error.isAxiosError &&
        error.response &&
        error.response.status === HttpStatusCodes.CSRF_TOKEN_MISSMATCH
      ) {
        if (retryCounter < maxRetries) {
          ++retryCounter;
          await getCsrfCookie();
          return axios.request(error.config);
        }
      }
      throw err;
    }
  );
};
