interface Sortable {
  createdAt: string;
}
const sortByCreatedAt = <T extends Sortable>(
  a: T,
  b: T,
  ascending: boolean
) => {
  if (a.createdAt === b.createdAt) {
    return 0;
  }
  const dir = ascending ? 1 : -1;

  return new Date(a.createdAt) > new Date(b.createdAt) ? 1 * dir : -1 * dir;
};

export const sortByCreatedAtAsc = <T extends Sortable>(a: T, b: T) =>
  sortByCreatedAt(a, b, true);
export const sortByCreatedAtDesc = <T extends Sortable>(a: T, b: T) =>
  sortByCreatedAt(a, b, false);
