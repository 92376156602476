import { RatioContainer } from '@card/core';
import { DOCUMENT_FORMAT_NAMES_MAP } from '@card/utils';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CssBaseline,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  GetApp as DownloadIcon,
  Language as GlobeIcon,
} from '@material-ui/icons';
import { sortByCreatedAtDesc, sortByUpdatedAtDesc } from '@shared/utils';
import { Title } from 'components/Title/Title';
import { getConfig } from 'config/getConfig';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { getOverviewData } from 'serviceClients/cardServiceClient';
import { TOverviewData } from 'serviceClients/TOverviewData';
import * as styles from './dashboardStyles.module.scss';

export const Dashboard: React.FC = () => {
  const [data, setData] = useState<TOverviewData | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadData = () => {
    setIsLoading(true);
    return getOverviewData().then((res) => {
      setData(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    loadData().then(() => {
      window.setInterval(() => {
        loadData();
      }, 1000 * 60 * 5);
    });
  }, []);

  return (
    <div>
      <CssBaseline />
      <div className={styles.container}>
        {isLoading && (
          <LinearProgress color="primary" className={styles.progress} />
        )}
        {data && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper className={styles.paper}>
                <Title>Users</Title>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell width="1">#</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Mail</TableCell>
                      <TableCell>Projects</TableCell>
                      <TableCell width="1">Created at</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.users
                      .sort(sortByCreatedAtDesc)
                      .slice(0, 40)
                      .map((user) => (
                        <TableRow key={user.id}>
                          <TableCell>
                            <Typography variant="body1">{user.id}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">{user.name}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              <Link href={`mailto:${user.email}`}>
                                {user.email}
                              </Link>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              {user.projectUserRoles
                                .map((role) => role.project?.name)
                                .join(', ')}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap variant="body1">
                              {dayjs(user.createdAt).format('DD.MM.YYYY HH:mm')}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper className={styles.paper}>
                <Title>Projects</Title>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell width="1">Created at</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.projects
                      .sort(sortByCreatedAtDesc)
                      .slice(0, 40)
                      .map((project) => (
                        <TableRow key={project.id}>
                          <TableCell title={project.id}>
                            <Typography variant="body1">
                              {project.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              {project.address}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              {project.projectUserRoles
                                .map(
                                  (role) => `${role.user.id}: ${role.user.name}`
                                )
                                .join(', ')}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap variant="body1">
                              {dayjs(project.createdAt).format(
                                'DD.MM.YYYY HH:mm'
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>

            <Grid item xs>
              <Grid container item spacing={2}>
                {data.cards
                  .filter((card) => card.recordType === 'draft')
                  .sort(sortByUpdatedAtDesc)
                  .slice(0, 6 * 20)
                  .map((card) => {
                    const updatedAt = dayjs(card.updatedAt);
                    const backgroundUrl = `url('${getConfig(
                      'CARD_THUMBNAILS_URL'
                    )}${card.id}/${updatedAt.valueOf()}.png')`;

                    const backgroundImage = getConfig('CARD_THUMBNAILS_ENABLED')
                      ? backgroundUrl
                      : undefined;

                    return (
                      <Grid item xs={2} key={card.id}>
                        <Card>
                          <RatioContainer ratio={1} flex>
                            <div className={styles.cardScreenshotWrapper}>
                              <div
                                className={styles.cardScreenshot}
                                style={{
                                  backgroundImage,
                                }}
                              />
                            </div>
                          </RatioContainer>
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h2"
                              noWrap
                            >
                              {card.name}
                            </Typography>

                            <Typography
                              variant="body1"
                              color="textSecondary"
                              component="p"
                            >
                              {card.project?.name ?? ''}
                              <br />
                              {`${card.pagesCount} ${
                                card.pagesCount === 1 ? 'Seite' : 'Seiten'
                              }`}
                              , {DOCUMENT_FORMAT_NAMES_MAP[card.documentFormat]}
                              <br />
                              Erstellt:{' '}
                              {dayjs(card.createdAt).format('DD.MM.YYYY HH:mm')}
                              <br />
                              Bearbeitet:{' '}
                              {dayjs(card.updatedAt).format(
                                'DD.MM.YYYY HH:mm'
                              ) + ` (${card.version})`}
                              <br />
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <div className={styles.cardActions}>
                              <div className={styles.left}>
                                {card.printJobs.length > 0 && (
                                  <div>
                                    <Typography
                                      variant="body1"
                                      component="span"
                                    >
                                      PDF ({card.printJobs.length}):
                                    </Typography>
                                    {card.printJobs
                                      .sort(sortByCreatedAtDesc)
                                      .slice(0, 3)
                                      .map((job) => (
                                        <IconButton
                                          key={job.id}
                                          size="small"
                                          href={job.downloadUrl ?? '#'}
                                          title={`${job.state}: ${dayjs(
                                            job.createdAt
                                          ).format('DD.MM.YYYY HH:mm')}`}
                                        >
                                          <DownloadIcon fontSize="small" />
                                        </IconButton>
                                      ))}
                                  </div>
                                )}
                                {card.publishedAt && (
                                  <div
                                    style={{ padding: 3 }}
                                    title={dayjs(card.updatedAt).format(
                                      'DD.MM.YYYY HH:mm'
                                    )}
                                  >
                                    <GlobeIcon fontSize="small" />
                                  </div>
                                )}
                              </div>
                              <Button
                                href={`${getConfig('EDITOR_URL')}?id=${
                                  card.id
                                }`}
                                size="small"
                                color="primary"
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="outlined"
                              >
                                Open
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};
